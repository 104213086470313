import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Embed } from '@components/Embed';

import { setBackgroundStyle } from '@lib/util';

import queryString from 'query-string';

const Soundcloud = (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_withWebp
                originalName
              }
            }
          }
        }
      }
    `
  );

  const title = `${props.artist} - ${props.title}`;
  const image = data.allImageSharp.edges.find((edge) => {
    return edge.node.fluid.originalName === `${props.cover}.webp`;
  });

  let type;

  switch (props.type) {
    case 'album':
    case 'albums':
      type = 'albums';
      break;

    case 'playlist':
    case 'playlists':
      type = 'playlists';
      break;

    case 'track':
    case 'tracks':
    case undefined:
    case '':
      type = 'tracks';
      break;

    default:
      throw Error('Invalid SoundCloud type provided.');
  }

  const url = new URL('https://w.soundcloud.com/player');
  const urlParams = {
    url: encodeURIComponent(`https://api.soundcloud.com/${type}/${props.id}`),
    color: props.color ? props.color : '#e63535',
    autoplay: props.autoplay ? props.autoplay : false,
    hide_related: props.hide_related ? props.hide_related : true,
    secret_token: props.secret_token ? props.secret_token : '',
    show_comments: props.show_comments ? props.show_comments : false,
    show_user: props.show_user ? props.show_user : false,
    show_reposts: props.show_reposts ? props.show_reposts : false,
    show_teaser: props.show_teaser ? props.show_teaser : false,
    visual: true,
  };

  url.search = queryString.stringify(urlParams);

  return (
    <Embed
      allow="autoplay"
      aspectRatio="1:1"
      frameBorder="0"
      loading="lazy"
      scrolling="no"
      src={url.href}
      style={setBackgroundStyle(image)}
      title={title} />
  );
};

export default Soundcloud;
