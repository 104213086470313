import React, { useEffect, useState } from 'react'

import { Content } from '@components/Content';
import { Mixcloud } from '@components/Mixcloud';
import { Seo } from '@components/Seo';
import { Soundcloud } from '@components/Soundcloud';

const MixesPage = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    (async() => {

      let { mixes } = await import('@data/mixes');

      setData(mixes.slice().sort().reverse());
    })();
  }, []);

  return (
    <>
      <Seo title="Mixes" description="A series of mixes from artists & friends of Nutriot Recordings" />
      <Content>
        {
          data.length && data.map(item => {
            return (
              <section
                key={item.id}
                className="item"
              >
                {
                  item.source === 'soundcloud'
                    ? (<Soundcloud {...item} />)
                    : (<Mixcloud {...item} />)
                }
              </section>

            )
          })
        }
      </Content>
    </>
  );
};

export default MixesPage
